@charset "utf-8";

$accentColor: #af2c22;
$accentColorHover: #d77f78;
$accentColorActive: #d75f55;
$accentColorDisabled: #bcbcbc;
$textColorDark: #2b2b2b;
$textColor: #575756;
$greyColor: #fafafa;
$whiteColor: #fff;
$greyColorDark: #e5e5e5;
$greyColorDarkHover: #dddddd;
$greyColorDarkPressed: #cbcbcb;
$greySecondaryColor: #7c7c7c;
$dangerColor: #ff2b2b;
$dangerColorHover: #ce0606;
$baseFontFamily: 'PT Sans', sans-serif;
$fontWeightBold: 700;
$fontWeightNormal: 400;
$transitionFast: 0.2s ease;
$borderRadiusSm: 4px;
$borderRadiusLg: 16px;
$contentFontSize: 16px;
$contentMaxWidth: 1500px;
$successColor: #41b06e;

// breakpoints
$tabletScreenWidth: 900px;
$mobileScreenWidth: 640px;
$mobileScreenWidth2: 935px;
$mobileScreenWidth1050: 1050px;
