.trajectory-page {
  display: flex;
  flex-direction: column;

  .button {
    margin-left: auto;
  }

  @include mobileScreen {
    & .button {
      width: 100%;
    }
  }
}
.level-page {
  display: flex;
  flex-direction: column;

  button {
    margin-left: auto;
  }

  @include mobileScreen {
    & .button {
      width: 100%;
    }
  }
}
