.level-list {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__level-element {
    box-sizing: border-box;
    padding: 24px;
    border: 1px solid $greyColorDark;
    border-radius: $borderRadiusSm;
    color: $textColorDark;
    white-space: pre-wrap;
    text-align: center;
    flex-grow: 1;

    transition: color ease-in 0.2s, border-color ease-in 0.2s,
      background-color ease-in 0.2s;

    cursor: pointer;

    ul li {
      margin-top: 16px;
      margin-left: 24px;
      list-style: disc;
    }

    ol li {
      margin-left: 24px;
      list-style: decimal;
    }

    // Hover

    &:hover {
      color: $accentColorHover;
      border-color: $accentColorHover;
    }

    // Active

    &.active {
      border-color: $accentColor;
      background-color: $accentColor;
      color: $whiteColor;
    }

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }
  }

  @include mobileScreen {
    margin-bottom: 30px;
    gap: 15px;

    &__level-element {
      padding: 16px 10px;
      flex: unset;
      width: 100%;
    }

    .button {
      width: 100%;
    }
  }
}
