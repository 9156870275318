@mixin visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

@mixin app-content-block {
  max-width: $contentMaxWidth;
  margin: 0 auto;
}

@mixin mobileScreen {
  @media screen and (max-width: $mobileScreenWidth) {
    @content;
  }
}

@mixin mobileScreen2 {
  @media screen and (max-width: $mobileScreenWidth2) {
    @content;
  }
}

@mixin mobileScreen1050 {
  @media screen and (max-width: $mobileScreenWidth1050) {
    @content;
  }
}

@mixin tabletScreen {
  @media screen and (max-width: $tabletScreenWidth) {
    @content;
  }
}
