.signCheckboxes {
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding-top: 12px;

  // signCheckboxes_error
  &_error {
    color: #ff2b2b;
    font-size: 12px;
  }
}

.globalError {
  color: #ff2b2b;
  font-size: 18px;
}

.globalError__info {
  margin-top: 50px !important;
}

.globalError a {
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}

.globalError a:hover {
  opacity: 0.6;
}
