.download-files-form {
  display: grid;
  grid-template-rows: auto;
  row-gap: 24px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.download-file-form__item {
  margin: 5px 0;
}

.download-files-form__header {
  border: 1px solid $accentColor;
  border-radius: 4px;
  padding: 12px;
}
